import React, { useEffect } from 'react'
import style from "./UserHome.module.css"
import UserHeader from "../partialpages/UserHeader"
import {useNavigate, useSearchParams} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from '../partialpages/Footer';

function UserHome() {
    const [queryParams,setQueryParams] = useSearchParams()

    const navigate = useNavigate()
    useEffect(() =>{
        if(queryParams.get("flag") !== null){
            toast.success(queryParams.get("msg"),{
                position:"top-right",
                autoClose: 1000,
                closeOnClick: true,
            })
            setTimeout(() =>{
                queryParams.delete("flag")
                setQueryParams(queryParams)
            },500)
        }       
    },[])
    
    const applicationHandle = (app_name) =>{
        navigate("/registrationform/"+app_name)
    }

  return (
    <>
        <UserHeader />
        <div className={style.homeContainer}>
            <div className={style.homeWrapper}>
                <div className={style.homeMessage}>
                    <h1>Which application do you want to start with?</h1>
                </div>
                <div className={style.homeOptions}>
                    {/* <div className={style.application} onClick={ () => applicationHandle("TR to PR")}>
                        <div className={style.imgContainer}>
                            <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
                        </div>
                        <div className={style.infoApplication}>
                            <h1> TR to PR </h1>
                            <span>To Know more about TR to PR</span>
                        </div>
                        <div className={style.price}>
                            <h4>Price</h4>
                            <h1>$500</h1>
                        </div>
                    </div> */}
                    <div className={style.application} onClick={() => applicationHandle("Permanent Residence")}>
                        <div className={style.imgContainer}>
                            <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
                        </div>
                        <div className={style.infoApplication}>
                            <h1> Permanent Residence </h1>
                            <span>To Know more about Permanent Residence </span>
                        </div>
                        <div className={style.price}>
                            <h4>Price</h4>
                            <h1>$950</h1>
                        </div>
                    </div>
                    <div className={style.application} onClick={() => applicationHandle("Study VISA")}>
                        <div className={style.imgContainer}>
                            <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
                        </div>
                        <div className={style.infoApplication}>
                            <h1>  Study VISA  </h1>
                            <span>To Know more about Study Visa</span>
                        </div>
                        <div className={style.price}>
                            <h4>Price</h4>
                            <h1>$200</h1>
                        </div>
                    </div>
                    <div className={style.application} onClick={() => applicationHandle("Work VISA")}>
                        <div className={style.imgContainer}>
                            <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
                        </div>
                        <div className={style.infoApplication}>
                            <h1> Work VISA </h1>
                            <span>To Know more about Work Visa</span>
                        </div>
                        <div className={style.price}>
                            <h4>Price</h4>
                            <h1>$200</h1>
                        </div>
                    </div>

                    <div className={style.application} onClick={() => applicationHandle("Visitor VISA")}>
                        <div className={style.imgContainer}>
                            <img src="https://tiffinhub.ca/assets/images/ca-flag.webp" alt="Canada Image"/>
                        </div>
                        <div className={style.infoApplication}>
                            <h1> Visitor VISA </h1>
                            <span>To Know more about Visitor Visa</span>
                        </div>
                        <div className={style.price}>
                            <h4>Price</h4>
                            <h1>$200</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <ToastContainer />
    </>
  )
}

export default UserHome