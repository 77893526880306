import * as yup from "yup"
import parse from "date-fns/parse"

export const userLogin = yup.object({
    email:yup.string().email({email:"Invalid Email Address"}).required({email:"This field is Required"}),
    password:yup.string().required().min(5,{pass:"Must be greater than 5 char"}),
})

export const userSignUp = yup.object({
    email:yup.string().email({email:"Invalid Email Address"}).required({email:"This field is Required"}),
    password:yup.string().required().min(5,{pass:"Must be greater than 5 char"}),
})


export const forgotPassword = yup.object({
    email:yup.string().email({email:"Invalid Email Address"}).required({email:"This field is Required"}),
})


export const registerForm = yup.object({
    // uci_number:yup.string().required({uci_number:"This field is Required"}),
    first_name:yup.string().required({first_name:"This field is Required"}),
    // last_name:yup.string().required({ last_name:"This field is Required"}),
})

export const bookAppointment = yup.object({
    phone: yup.string().min(10,{phone:"Phone no. must be 10 char"}).max(10,{phone:"Phone no. must be 10 char"}).required(),
    name:yup.string().required({name:"This field is required"}),
    email:yup.string().required({email:"This field is required"}).email({email:"Invalid Email Address"}),
    date: yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "dd.MM.yyyy", new Date());
      return result;
    })
    .typeError({date:"Please enter a valid date"})
    .required()
    .min( new Date(),{date: "Please enter future date"}),
    time:yup.string().required({time:"This Field is required"})
})

export const  contactus = yup.object({
    name:yup.string().required({name:"This field is required"}),
    email:yup.string().required({email:"This field is required"}).email({email:"Invalid Email Address"}),
    message:yup.string().required({msg:"This field is required"}),
})

export const updatePassword = yup.object({
    newPassword:yup.string().required().min(5,{passNew:"Must be greater than 5 char"}),
    confPassword:yup.string().required().min(5,{passConf:"Must be greater than 5 char"}),
})