import React,{useState} from 'react'
import style from './BookAppointment.module.css'
import UserHeader from "../partialpages/UserHeader"
import axios from 'axios'
import { ToastContainer,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate,Link} from "react-router-dom"
import {TALK_TO__US_API} from "../../API/index"
import Loader from '../Utilities/Loader'
import Footer from '../partialpages/Footer'
import {bookAppointment} from "../../Validations/userValidations"


function BookAppointment() {
  let Email = ""
  if(sessionStorage.getItem("userToken") !== null){
    Email = sessionStorage.getItem("userEmail")
  }
  const [currentErrors, setCurrentErrors] = useState([])
  const [aT,setAT] = useState(false)
  const navigate = useNavigate()
  const [isPopUp,setIsPopUp] = useState(false)
  const [isLoading,setIsLoading] = useState(false)
  const [bookName,setBookName] =useState("")
  const [bookEmail,setBookEmail] =useState(Email)
  const [bookPhone,setBookPhone] =useState("")
  const [applicationSelect,setApplicationSelect] = useState("Select Your Application")
  const [date,setDate] = useState("")
  const [time,setTime] = useState("")

  const bookNameHandler = (e) =>{
    setBookName(e.target.value)
  }
  const bookPhoneHandler = (e) =>{
    setBookPhone(e.target.value)
  }
  const bookEmailHandler = (e) =>{
    setBookEmail(e.target.value)
  }
  const applicationSelectHandler = (e) =>{
    setApplicationSelect(e.target.value)
  }

  const dateHandler = (e) =>{
    setDate(e.target.value)

  }
  const timeHandler = (e) =>{
    setTime(e.target.value)

  }
  const submitHandler = (e) =>{
    e.preventDefault()

    const validData = {
      name:bookName,
      email:bookEmail,
      phone:bookPhone,
      time:time,
      date:date,
    }

    applicationSelect === "Select Your Application" ? setAT(true) : setAT(false)

    bookAppointment.validate(validData,{ abortEarly: false })
    .then((res) =>{  
      if(applicationSelect === "Select Your Application"){
        console.log("pass")
      }else{
        setIsLoading(true)
        const url = TALK_TO__US_API
        const token = sessionStorage.getItem("userToken")
        const data = {
          ...validData,
          time:time+":00",
          application_type:applicationSelect
        } 
        console.log(data)
        const config ={
          headers :{
            "token":token,
            'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
            "Content-Type": "application/json",
          }
        }
        axios.post(url,data,config)
        .then(
          (response) =>{
            const result = JSON.parse(response.data)
            if(result.code === 1){
              setIsLoading(false)
              toast.success(result.message,{
                  position:"top-right",
                  autoClose: 1000,
                  closeOnClick: true,
              })
              setApplicationSelect("Select Your Application")
              setBookName("")
              setBookPhone("")
              setDate("")
              setTime("")
              setIsPopUp(true)
              navigate("/bookappointment")
            }else{
              setIsLoading(false)
              toast.error(result.message,{
                  position:"top-right",
                  autoClose: 1000,
                  closeOnClick: true,
              })
              setApplicationSelect("Select Your Application")
              setBookName("")
              setBookPhone("")
              setDate("")
              setTime("")
              navigate("/bookappointment")
            }
          }
          ).catch((error) =>{
            setIsLoading(false)
            toast.error(error.message,{
                position:"top-right",
                autoClose: 1000,
                closeOnClick: true,
            })
          })
      }
      setCurrentErrors([])
    }).catch((err) =>{
        setCurrentErrors(err.errors)
    })

  }
  const stayHandler = () =>{
    setIsPopUp(false)
  }
  return (
    <>
      <UserHeader />
      <div className={style.bookContainer}>
        <div className={style.bookWrapper}>
          <div className={style.bookMessage}>
            <h1>Book Your Appointment</h1>
            <p>We do not charge for the appointments</p>
          </div>
          <form>
            <div className={style.bookFormData}>
              <div className={style.bookLeft}>
                  <div>
                    <div>
                      <input type="text" value={bookName} required onChange={bookNameHandler}/>
                      <label>Name</label>
                    </div>
                    {currentErrors.map((e,index) =>(
                        <div style={{
                            fontSize:"0.7rem",
                            fontWeight:"700",
                            color:"red",
                            marginTop:"3px",
                        }}  key={index}>{e.name}</div>
                    ))
                    }
                  </div>
                  <div>
                    <div>
                      <input type="text" value={bookEmail} required onChange={bookEmailHandler}/>
                      <label>Email address</label>
                    </div>
                    {currentErrors.map((e,index) =>(
                        <div style={{
                            fontSize:"0.7rem",
                            fontWeight:"700",
                            color:"red",
                            marginTop:"3px",
                        }}  key={index}>{e.email}</div>
                    ))
                    }
                  </div>
                  <div>
                    <div>
                        <input type="number" value={bookPhone} className={style.phn} required onChange={bookPhoneHandler}/>
                        <label>Phone No.</label>
                    </div>
                    {currentErrors.map((e,index) =>(
                        <div style={{
                            fontSize:"0.7rem",
                            fontWeight:"700",
                            color:"red",
                            marginTop:"3px",
                        }}  key={index}>{e.phone}</div>
                    ))
                    }
                  </div>
              </div>
              <div className={style.bookRight}>
                  <div>
                    <div>
                      <select onChange={applicationSelectHandler} value ={applicationSelect}>
                          <option selected disabled>Select Your Application </option>
                          <option value="TR to PR">TR to PR</option>
                          <option value="Permanent Residence">Permanent Residence</option>
                          <option value="Study VISA">Study VISA</option>
                          <option value="Work VISA">Work VISA</option>
                          <option value="Visitor VISA">Visitor VISA</option>
                      </select>
                    </div>
                    {
                      aT && <div style={{
                                      fontSize:"0.7rem",
                                      fontWeight:"700",
                                      color:"red",
                                      marginTop:"3px",}}>
                                          Please select one option
                                      </div>
                  }
                  </div>
                  <div className={style.bookDate}>
                    <label>Select your date</label>
                    <div>
                      <input type="date" value={date}  onChange={dateHandler} />
                      {currentErrors.map((e,index) =>(
                          <div style={{
                              fontSize:"0.7rem",
                              fontWeight:"700",
                              marginTop:"3px",
                              color:"red",
                          }}  key={index}>{e.date}</div>
                      ))
                      }
                    </div>
                  </div>
                  <div className={style.bookDate}>
                    <label>Select your time</label>
                    <div>
                      <input type="time" value={time}  onChange={timeHandler} />
                      {currentErrors.map((e,index) =>(
                          <div style={{
                              fontSize:"0.7rem",
                              fontWeight:"700",
                              marginTop:"3px",
                              color:"red",
                          }}  key={index}>{e.time}</div>
                      ))
                      }
                    </div>
                  </div>
                  <div>
                  </div>
              </div>
            </div>
            <div className={style.bookNow}>
              <button className={`${isLoading && style.disableBtn}`} onClick={submitHandler} type='button'>Book Now</button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
        {
          isLoading && <Loader />  
        }
        {
          isPopUp && 
          <>

            <div className={style.popupContainer}>
              <div className={style.popupWrapper}>
                  <div className={style.popUpMsg}>
                      <h3> We will contact you soon ! </h3>
                  </div>
                  <div className={style.popupBtns}>
                      <Link to="/home"> Home </Link>
                      <Link onClick={stayHandler} to="/bookappointment"> stay </Link>
                  </div>
              </div>
            </div>
          </>
        }
      <ToastContainer />
    </>
  )
}

export default BookAppointment