import axios from 'axios'
import React, { useEffect } from 'react'
import style from "./AppInfo.module.css" 
import { useParams } from 'react-router-dom'
import { GET_FILE_DETAILS_API } from '../../API'
import { useState } from 'react'
import UserHeader from '../partialpages/UserHeader'
import Footer from '../partialpages/Footer'
import Loader from '../Utilities/Loader'
import { ToastContainer,toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from "react-router-dom"
import { FILES_AMOUNT } from '../../Constants'
import {Base64} from "../../EncryptDecrypt/index"
import { set } from 'date-fns'


function AppInfo() {
    const [date,setDate] = useState("")
    const navigate = useNavigate()
    const {id} = useParams()
    const [fileInfo,setFileInfo] = useState({})
    const [isLoading,setIsLoading] = useState(false)
    const [filesLoading,setFilesLoading] = useState(false)
    const [status,setStatus] = useState(false)

    useEffect(() =>{
        setIsLoading(true)
        const url = GET_FILE_DETAILS_API
        const token = sessionStorage.getItem("userToken")
        const config = {
            headers:{
                "token":token,
                'secret_key': 'K9J920XCVK5B609d263bf3d777c31c234ced', 
                "Content-Type": "application/json",
            }
        }
        const data = {
            id:id,
        }
        axios.post(url,data,config)
        .then((response) =>{
            console.log(JSON.parse(response.data))
            const result = JSON.parse(response.data)
            setFileInfo(result.data.data[0])
            if(result.code === 1){
                setFilesLoading(true)
                setStatus(true)
                setIsLoading(false)
                toast.success(result.message,{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            }else{
                setStatus(false)
                setIsLoading(false)
                setFilesLoading(true)
                toast.error("No File Info Found",{
                    position:"top-right",
                    autoClose: 1000,
                    closeOnClick: true,
                })
            }
        }).catch((error) =>{
            console.log(error.message)
        })
    },[])

    const payHandler = (app,amount,id) =>{
        const url = Base64.encode(`tid=${id}&appName=${app}&amount=${amount}&description=To Know more about ${app}`)
        navigate(`/pay?code=${url}`)
    }
  return (
    <>
        <UserHeader />
        <div className={style.infoContainer}>
            <div className={style.infoWrapper}>
                <h1>My File Information</h1>
                {
                    filesLoading ?
                    <>
                    {
                        status ?
                        <>
                            <div className={style.content}>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Name </h3>
                                    </div>
                                    <div className={style.value}>
                                        {fileInfo.Name}
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Application Type </h3>
                                    </div>
                                    <div className={style.value}>
                                        {fileInfo.Application_Type}
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Application Status </h3>
                                    </div>
                                    <div className={style.value}>
                                        {fileInfo.Status}
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Payment </h3>
                                    </div>
                                    <div className={style.value}>
                                        {fileInfo.Transaction_Id === null ? <button  style={{backgroundColor:"red",color:"white"}} onClick={() => payHandler(fileInfo.Application_Type,FILES_AMOUNT[fileInfo.Application_Type],id)}>Pay Now</button> : 
                                        <button style={{backgroundColor:"green",color:"white"}}>Paid</button> }
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Assigned Agent </h3>
                                    </div>
                                    <div className={style.value}>
                                        {fileInfo.Assigned_To === null ? "Not Assigned Yet" : fileInfo["Assigned_To.Name"]}
                                    </div>
                                </div>
                                <div className={style.tile}>
                                    <div className={style.key}>
                                        <h3>Registered on </h3>
                                    </div>
                                    <div className={style.value}>
                                        {new Date(fileInfo.Created_Time).toLocaleDateString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}
                                    </div>
                                </div>
                                <div>
                                    <a className={style.folder} href={fileInfo.Folder_Url} target="_blank">Upload Additional Document</a>
                                </div>
                            </div>
                        </>:
                        <>
                            <div style={{
                                width:"100%",
                                height:"inherit",
                                display:"flex",
                                flexDirection:"column",
                                gap:"1rem",
                                justifyContent:"center",
                                alignItems:"center"
                            }}>
                                <h1 style={{
                                    width:"100%",
                                    textAlign:"center",
                                }}>No File Info Found</h1>
                            </div>
                        
                        </>
                    }
                    
                    </>
                    :
                    <>
                        <div>
                            <h1 className={style.appLoad}>File Info Loading...</h1>
                        </div>
                    </>
                }
            </div>
        </div>
        <Footer />
        {
            isLoading && <Loader />
        }
        <ToastContainer />
    </>
  )
}

export default AppInfo