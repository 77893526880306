export const REGISTER_API = "https://apis.stackimmigration.com/api/register"

export const LOGIN_API = "https://apis.stackimmigration.com/api/login"

export const VERIFY_EMAIL_API = "https://apis.stackimmigration.com/api/verify_email"

export const FORGOT_PASSWORD_API = "https://apis.stackimmigration.com/api/forgot_password"

export const UPDATE_FORGOT_PASSWORD_API = "https://apis.stackimmigration.com/api/forgot_update_password"

export const CONTACT_US_API = "https://apis.stackimmigration.com/api/contact_us"

export const TALK_TO__US_API = "https://apis.stackimmigration.com/api/talk_to_us"

export const MY_FILES_API = "https://apis.stackimmigration.com/api/my_files"

export const UPLOAD_FILES_API = "https://apis.stackimmigration.com/api/upload_files"

export const CREATE_AGENT_API = "https://apis.stackimmigration.com/api/create_agent"

export const AGENT_LOGIN_API = "https://apis.stackimmigration.com/api/agent_login"

export const AGENT_EMAIL_VERIFY_API = "https://apis.stackimmigration.com/api/agent_verify_email"

export const AGENT_FILES_API = "https://apis.stackimmigration.com/api/agent_files"

export const AGENT_FORGOT_PASSWORD_API = "https://apis.stackimmigration.com/api/agent_forgot_password"

export const AGENT_UPDATE_FORGOT_PASSWORD_API = "https://apis.stackimmigration.com/api/agent_forgot_password_update"

export const MAKE_PAYMENT_API = "https://apis.stackimmigration.com/api/make_payment"

export const GET_FILE_DETAILS_API = "https://apis.stackimmigration.com/api/get_file_details"

export const UPDATE_FILE_STATUS_API = "https://apis.stackimmigration.com/api/update_file_status"

